import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AvatarComponent } from "./avatar/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/avatar.component";
import { AvatarControlsComponent } from "./avatar-controls/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/avatar-controls.component";
import { AvatarConversationBoardComponent } from "./avatar-conversation-board/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/avatar-conversation-board.component";
import { DragDropComponent } from "./avatar-drag-drop/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/avatar-drag-drop.component";
import { FeedbackComponent } from "./avatar-feedback/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/avatar-feedback.component";
import { AvatarHeadingComponent } from "./avatar-heading/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/avatar-heading.component";
import { AvatarInitComponent } from "./avatar-init/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/avatar-init.component";
import { AvatarVideoComponent } from "./avatar-video/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/avatar-video.component";
import { ShortcutsComponent } from "./avatar-shortcuts/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/avatar-shortcuts.component";
import { ContentModalComponent } from "./content-modal/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/content-modal.component";
import { LandingPageComponent } from "./landing-page/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/landing-page.component";
import { LanguagesComponent } from "./languages/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/languages.component";
import { LoadingComponent } from "./loading/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/loading.component";
import { MessageComponent } from "./message/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/message.component";
import { MessageOnAudioComponent } from "./message-on-audio/design/7313363a-d0d2-49e1-a306-78002ef9d1bc/message-on-audio.component";
import { ThumbUpSvgComponent } from "./message/svg/thumbs-up";
import { ThumbDownSvgComponent } from "./message/svg/thumbs-down";
import { AttachmentSvgComponent } from './avatar-controls/svg/attachment';
import { MicrophoneSvgComponent } from './avatar-controls/svg/microphone';
import { CloseSvgComponent } from "./avatar-feedback/svg/close";
import { SafePipe } from 'app/pipes/safe.pipe';

@NgModule({
    declarations: [
        AvatarComponent,
        AvatarControlsComponent,
        AvatarConversationBoardComponent,
        DragDropComponent,
        FeedbackComponent,
        AvatarHeadingComponent,
        AvatarInitComponent,
        AvatarVideoComponent,
        ShortcutsComponent,
        ContentModalComponent,
        LandingPageComponent,
        LanguagesComponent,
        LoadingComponent,
        MessageComponent,
        MessageOnAudioComponent,
        ThumbUpSvgComponent,
        ThumbDownSvgComponent,
        CloseSvgComponent,
        AttachmentSvgComponent,
        MicrophoneSvgComponent,
        SafePipe
    ],
    exports: [
        AvatarComponent,
        AvatarControlsComponent,
        AvatarConversationBoardComponent,
        DragDropComponent,
        FeedbackComponent,
        AvatarHeadingComponent,
        AvatarInitComponent,
        AvatarVideoComponent,
        ShortcutsComponent,
        ContentModalComponent,
        LandingPageComponent,
        LanguagesComponent,
        LoadingComponent,
        MessageComponent,
        MessageOnAudioComponent,
        ThumbUpSvgComponent,
        ThumbDownSvgComponent,
        CloseSvgComponent,
        AttachmentSvgComponent,
        MicrophoneSvgComponent,
        SafePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgOptimizedImage
    ]
})
export class ComponentModule {}
